import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import block from "../Images/others/homeflownew.drawio.svg";

export const MangSecurity = () => {
  return (
    <>
      {/* bg-[#18091C] */}
      <div className="bg-white  xl:py-20 lg:py-12 md:py-10 sm:py-10 py-10 xl:px-24 lg:px-10 sm:px-12 md:px-10 px-16 ">
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          <h3 className=" text-2xl xl:text-5xl lg:text-4xl md:text-3xl sm:text-xl  font-bold text-center   mb-10   ">
            <span className="k3serv">K3 Company Services</span>
          </h3>

          <h1 className="text-[#FFD23F] xl:text-4xl  font-semibold text-center mb-5">
            Introducing K3, your one-stop shop for creating a life you love!
          </h1>
        </div>
        <p
          className="  tracking-wider text-justify text-[12px] xl:text-xl lg:text-lg md:text-base sm:text-xs  text-black "
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          Welcome to K3, a distinctive service-based company dedicated to
          elevating and streamlining various aspects of your life. Our mission
          is to simplify and enhance your experiences, offering a diverse range
          of services tailored to meet your unique needs and aspirations.
          <br />
          <br />
          At K3, we understand that life is a tapestry of dreams and desires,
          and we are here to transform those aspirations into reality. Whether
          you envision an unforgettable adventure, a thoughtfully curated home,
          or a stress-free relocation, K3 is your partner in turning these
          dreams into tangible, enjoyable experiences. Our team comprises
          experts with a wealth of knowledge and resources, ready to navigate
          the complexities and intricacies of your requests. From crafting
          personalized travel adventures to curating the perfect living space or
          facilitating seamless relocations, K3 is committed to exceeding your
          expectations.
          <br />
          <br />
          Experience the difference with K3 as we embark on a journey to
          simplify, enhance, and enrich your life. Let us be the architects of
          your aspirations, bringing innovation, expertise, and personalized
          service to every facet of your unique journey.
        </p>
      </div>

      <div className="bg-bycolor bg-fixed  ">
        <div class="container">
          <div class="box">
            <span></span>
            <div class="content">
              <h2>Tours & Travels</h2>
              <p>
                Being one of the best travels in Chennai, K3 Travels provides
                you with the quality Cars, Vans, and Buses rental services for
                all your travel needs. We craft seamless journeys.
              </p>
              <button class="glow-on-hover" type="button">
                {" "}
                <a href="../services/travels" target="_blank">
                  {" "}
                  Explore
                </a>
              </button>
            </div>
          </div>
          <div class="box">
            <span></span>
            <div class="content">
              <h2>Small Finance</h2>
              <p>
                Empower your dreams with our K3 small finance solutions. Explore
                smart savings strategies, responsible investing, and debt
                management to reach your financial goals.
              </p>
              <button class="glow-on-hover" type="button">
                {" "}
                <a href="../services/finance" target="_blank">
                  {" "}
                  Explore
                </a>
              </button>
            </div>
          </div>
          <div class="box">
            <span></span>
            <div class="content">
              <h2>Catering</h2>
              <p>
                "K3 Catering" is one of the best catering service in chennai. We
                really care about the client, the success of the event. We
                believe every occasion should be an elegant occasion.
              </p>
              <button class="glow-on-hover" type="button">
                {" "}
                <a href="../services/catering" target="_blank">
                  {" "}
                  Explore
                </a>
              </button>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="box">
            <span></span>
            <div class="content">
              <h2>Real Estate</h2>
              <p>
                Elevate your living with our K3 real estate offerings. From
                luxurious residences to strategic commercial spaces.We guide you
                from dream home to reality.
              </p>
              <button class="glow-on-hover" type="button">
                {" "}
                <a href="../services/realestate" target="_blank">
                  {" "}
                  Explore
                </a>
              </button>
            </div>
          </div>
          <div class="box">
            <span></span>
            <div class="content">
              <h2>Home Interior</h2>
              <p>
                Blend warm textures, soft lighting, and personal touches to
                create a space that reflects your unique style and invites
                relaxation.
              </p>
              <button class="glow-on-hover" type="button">
                {" "}
                <a href="../services/interiordesign" target="_blank">
                  {" "}
                  Explore
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
