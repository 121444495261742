import React from "react";
import realEstate from "../Images/Finance/f1.jpg";
import catering from "../Images/Finance/f2.jpg";
import Finance from "../Images/Finance/f3.avif";
import Tours from "../Images/Finance/f4.jpg";

export const FinanceCard = () => {
  return (
    <>
      <div className="bg-white">
        <h1
          className=" font-bold  text-center mb-10 text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl pt-20 "
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <span className="k3serv "> K3’s Finance Advantage </span>
        </h1>
        <p
          className="  text-center text-violet text-2xl lg:text-3xl xl:text-3xl md:text-3xl sm:text-3xl "
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          Here’s how we make our finance offerings Fast, Flexible and Friendly!
        </p>
      </div>

      <div class="flex flex-wrap justify-center bg-white xl:py-10 lg:py-10 md:py-10 sm:py-10 py-10 xl:px-7 ">
        <div class="w-2/3 sm:2/3 md:w-2/5 lg:w-1/3 xl:w-1/5 rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-[#5B3752] text-sm lg:text-xl xl:text-lg md:text-base sm:text-base mb-2 text-center">
              Secured Data System
            </div>
            <img class="w-full" src={realEstate} alt="Card image" />
            <p class="text-gray-700 text-justify text-base mt-5">
              Prioritize your data security, ensuring that your information
              remains protected and confidential.
            </p>
          </div>
        </div>
        <div class="w-2/3 sm:2/3 md:w-2/5 lg:w-1/3 xl:w-1/5  rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-[#5B3752] text-sm lg:text-xl xl:text-lg md:text-base sm:text-base mb-2 text-center">
              Customized Personal Finance
            </div>
            <img class="w-full" src={catering} alt="Card image" />
            <p class="text-gray-700 text-justify  text-base mt-5">
              We provide you with a range of options for Finance advisors so you
              can find the one that best suits your requirements.
            </p>
          </div>
        </div>
        <div class="w-2/3 sm:2/3 md:w-2/5 lg:w-1/3 xl:w-1/5  rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-[#5B3752] text-sm lg:text-xl xl:text-lg md:text-base sm:text-base mb-2 text-center">
              User-friendly
            </div>
            <img class="w-full" src={Finance} alt="Card image" />
            <p class="text-gray-700 text-justify  text-base mt-5">
              Easy to use, convenient & user friendly for a seamless customer
              journey.
            </p>
          </div>
        </div>
        <div class="w-2/3 sm:2/3 md:w-2/5 lg:w-1/3 xl:w-1/5  rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-[#5B3752] text-sm lg:text-xl xl:text-lg md:text-base sm:text-base mb-2 text-center">
              Flexible Repayment Option
            </div>
            <img class="w-full" src={Tours} alt="Card image" />
            <p class="text-gray-700 text-justify  text-base mt-5">
              Simple, practical, and user-friendly for a flawless customer
              experience
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
