import React from "react";
import icoq1 from "../Images/k3/realestate/bi_people-fill-1.svg";
import icoq2 from "../Images/k3/realestate/bi_people-fill-2.svg";
import icoq3 from "../Images/k3/realestate/bi_people-fill-3.svg";
import icoq4 from "../Images/k3/realestate/bi_people-fill.svg";
import icoq5 from "../Images/k3/realestate/mdi_account-online-1.svg";
import icoq6 from "../Images/k3/realestate/mdi_account-online.svg";

export const Realnext = () => {
  return (
    <>
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-4 bg-white py-32 px-24 ">
        <div
          className=" p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq5} alt="Image 1" className="w-auto h-auto icoo" />
          <h1 className="text-[#472B40] text-lg lg:text-3xl xl:text-2xl md:text-xl sm:text-xl font-bold text-center mt-3">
            FIND THE RIGHT TENANTS
          </h1>
          <p className="py-5  ">
            Give it to us to find verified tenants quickly!
          </p>
        </div>
        <div
          className="p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq6} alt="Image 2" className="w-auto h-auto icoo" />
          <h1 className="text-[#472B40f] text-lg lg:text-3xl xl:text-2xl md:text-xl sm:text-xl  font-bold text-center mt-3">
            YOUR SUPPORT, ALL TIME!
          </h1>
          <p className="py-5  ">
            Taking care of your property and tenants.
          </p>
        </div>
        <div
          className=" p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq3} alt="Image 3" className="w-auto h-auto icoo" />
          <h1 className="text-[#472B40] text-lg lg:text-3xl xl:text-2xl md:text-xl sm:text-xl  font-bold text-center mt-3">
            IMPROVING PROPERTY VALUE
          </h1>
          <p className="py-5   ">
            Observe Your Home's Value Increasing Without Any Work.
          </p>
        </div>
        <div
          className=" p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq4} alt="Image 4" className="w-auto h-auto icoo" />
          <h1 className="text-[#472B40] text-lg lg:text-3xl xl:text-2xl md:text-xl sm:text-xl  font-bold text-center  mt-3">
            RENOVATING ROOMS WITH RELIABILITY!
          </h1>
          <p className="py-5  ">
            Enhance Your Living Spaces with Painting and Interior Services.
          </p>
        </div>
        <div
          className=" p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq1} alt="Image 4" className="w-auto h-auto icoo" />
          <h1 className="text-[#472B40] text-lg lg:text-3xl xl:text-2xl md:text-xl sm:text-xl  text-center font-bold mt-3">
            OFFICIAL RECORDS
          </h1>
          <p className="py-5  ">
            Simplified Legal Support & Documentation
          </p>
        </div>
        <div
          className=" p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq2} alt="Image 4" className="w-auto h-auto icoo" />
          <h1 className="text-[#472B40] text-lg lg:text-3xl xl:text-2xl md:text-xl sm:text-xl  text-center font-bold mt-3">
            GUARANTING ON-TIME TRANSACTIONS
          </h1>
          <p className="py-5  ">
            Come and Enjoy Worry-Free Payment Assurance with Us for On-Time
            Rentals.
          </p>
        </div>
      </div>
    </>
  );
};
