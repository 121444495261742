import { IoLogoWhatsapp } from "react-icons/io";

const ScrollToTopButton = () => {
  return (
    <div
      className={`fixed cursor-pointer  bg-white rounded-full  bottom-10  right-5`}
    >
      <a
        href="https://api.whatsapp.com/send?phone=919940383228"
        target="_blank"
      >
        <IoLogoWhatsapp color="green" size={55} />
      </a>
    </div>
  );
};

export default ScrollToTopButton;
