import React from "react";
import realEstate from "../Images/Travel/t1.avif";
import catering from "../Images/Travel/t2.avif";
import Finance from "../Images/Travel/t3.jpg";
import Tours from "../Images/Travel/t4.avif";

export const Travswing = () => {
  return (
    <>
      <div
        id="cards_landscape_wrap-2"
        className="text-center bg-white flex justify-center xl:px-11 lg:px-12 md:px-16 sm:px-11 px-10 py-12 xl:py-20 lg:py-20 md:py-16 sm:py-12"
      >
        <div className="container">
          <div className="flex flex-wrap justify-center ">
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:h-1/4 px-4">
              <a href="">
                <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                  <div className="text-box">
                    <div className="image-box">
                      <img
                        src={realEstate}
                        alt=""
                        className="w-full h-48 object-cover"
                      />
                    </div>
                    <div className="text-container  ">
                      <h6 className="xl:text-lg sm:text-base md:text-base text-sm   lg:text-xl  text-[#5B3752]">
                        Journey Beyond Boundaries
                      </h6>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:h-1/4 px-4">
              <a href="">
                <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                  <div className="text-box">
                    <div className="image-box">
                      <img
                        src={catering}
                        alt=""
                        className="w-full h-48 object-cover"
                      />
                    </div>
                    <div className="text-container p-6">
                      <h6 className="text-xl  text-[#5B3752]">
                        Adventure Awaits
                      </h6>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:h-1/4 px-4">
              {/* <a href=""> */}
              <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                <div className="text-box">
                  <div className="image-box">
                    <img
                      src={Finance}
                      alt=""
                      className="w-full h-48 object-cover"
                    />
                  </div>
                  <div className="text-container p-6">
                    <h6 className="text-xl  text-[#5B3752]">
                      From Dream to Destination
                    </h6>
                  </div>
                </div>
              </div>
              {/* </a> */}
            </div>
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:h-1/4 px-4">
              <a href="">
                <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                  <div className="text-box">
                    <div className="image-box">
                      <img
                        src={Tours}
                        alt=""
                        className="w-full h-48 object-cover"
                      />
                    </div>
                    <div className="text-container p-6">
                      <h6 className="text-xl text-[#5B3752]">
                        Explore the World, Your Way
                      </h6>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
