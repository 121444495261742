import React from "react";
import tile1 from "../Images/HomeInterior/tiles.jpg";
import tile2 from "../Images/HomeInterior/blue.jpg";
import tile3 from "../Images/HomeInterior/float.jpg";
import tile4 from "../Images/HomeInterior/crock.jpg";
import Finance from "../Images/Travel/innova-crysta-2.jpg";
import Tours from "../Images/Travel/download.jpeg";

export const InteriorMulti = () => {
  return (
    <>
      <div>
        <div className="py-10 bg-[#472B40] bg-fixed">
          <h6 className="text-3xl text-center font-bold text-[#F1EAFF] mb-5">
            Designs For Every Budget And Lifestyle
          </h6>
          <h6 className="text-xl text-center font-bold text-white ">
            Smart Modular Kitchen Designs
          </h6>
        </div>
        <div
          id="cards_landscape_wrap-2"
          className="text-center bg-white px-10"
        >
          <div className="container pb-20 ">
            <div className="flex flex-wrap  justify-center ">
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile1}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <h6 className="text-xl font-bold text-[#472B40]">
                          Kitchen with Glass Cabinets
                        </h6>
                        <p className="mt-10 ">
                          {" "}
                          Enhance your kitchen's aesthetic and functionality
                          with sleek floating shelves, providing a stylish
                          solution for organized storage while creating an open
                          and airy feel.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile2}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <h6 className="text-xl font-bold text-[#472B40]">
                          Two tone kitchen with Dinning
                        </h6>
                        <p className="mt-10 ">
                          {" "}
                          Transform your kitchen with a stylish two-tone
                          palette, blending contrasting colors for a modern and
                          visually dynamic culinary space that reflects your
                          unique taste.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile3}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <h6 className="text-xl font-bold text-[#472B40]">
                          Kitchen with Floating Shelves
                        </h6>
                        <p className="mt-10 ">
                          {" "}
                          Create a modern and functional kitchen with floating
                          shelves, maximizing storage and showcasing stylish
                          dishware point for a sleek and organized culinary
                          space.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile4}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <h6 className="text-xl font-bold text-[#472B40]">
                          Sleek & spacious crockery unit
                        </h6>
                        <p className="mt-10 ">
                          {" "}
                          Introducing a sleek and spacious crockery unit,
                          seamlessly blending modern design with ample storage
                          to elevate your dining space with both style and
                          functionality.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
