import React from "react";
import manage from "../Images/others/Group57.png";
import "aos/dist/aos.css";
// import chennai from "../Images/k3/home/office.jpg";

export const AbouTops = () => {
  return (
    <>
      <div className=" bg-abu  bg-cover h-screen items-center  px-12 xl:px-16  lg:px-20  xl:py-40 lg:py-36 md:py-28 sm:py-32 sm:px-10 md:px-9  py-32 ">
        <div className="  text-center">
          <h1 className=" font-extrabold k3com text-[#5B3752] text-2xl lg:text-5xl xl:text-7xl md:text-3xl sm:text-3xl mb-6">
            About Us
          </h1>
        </div>
        <div className="flex items-center justify-center px-48">
          <p className="mt-6 text-xl text-white leading-relaxed ">
            {" "}
            At K3 Company, we create amazing experiences instead of just
            providing services. Our goal is to make your life easier and more
            enjoyable. K3 is your committed partner whether it's planning
            life-changing experiences, selecting stunning residences, or
            guaranteeing stress-free moves. We turn your aspirations into
            reality with a team of experts and a dedication to individualized
            care. Choose K3 if you value creativity, knowledge, and a drive to
            go above and beyond. Join us to improve your living everything we do
            is planned to be extraordinary.
          </p>
        </div>
      </div>
    </>
  );
};
