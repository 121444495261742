import React from "react";
import c1 from "../Images/Finance/a1.jpg";
import c2 from "../Images/Finance/a2.jpg";
import c3 from "../Images/Finance/money-167735.jpg";
import c4 from "../Images/Finance/a4.jpg";
import chennai from "../Images/Finance/money-home-coin-investment.jpg";

export const SmallFinance = () => {
  return (
    <>
      <div className="bg-financeback bg-center bg-cover h-screen  flex justify-center items-center">
        <div
          className="designcard  text-center p-12 m-5"
          data-aos="flip-right"
          data-aos-duration="700"
        >
          <h6 className="primary-text ">K3 Finance Company</h6>
          <p className="secondary-text">Small Finance , Big Future</p>
        </div>
      </div>
      <div className="bg-white pt-10">
        <div class="containertest">
          <div>
            <img src={c1} alt="bike" />
          </div>

          <div>
            <img src={c2} alt="rose" />
          </div>

          <div>
            <img src={c3} alt="camera" />
          </div>

          <div>
            <img src={c4} alt="road" />
          </div>
        </div>
      </div>

      <div className="bg-white py-20 xl:px-28 lg:px-48 md:px-28 sm:px-40 px-6">
        <h1
          className="text-center  font-extrabold  text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl mb-8 "
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <span className="k3serv">K3 Small Finance</span>
        </h1>

        <div
          className="font-normal text-justify  lg:text-base lg:mt-5 md:mt-3 sm:mt-2  xl:text-base sm:text-xs md:text-base text-base  "
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <p className="mb-2">
            In the fast-paced world of finance, managing your small business's
            financial affairs is crucial for success. At K3 Finance, we
            understand the unique challenges faced by small businesses and offer
            tailored financial solutions to ensure your financial stability and
            growth.
          </p>

          <p className="mb-2">
            Our experienced team is dedicated to providing personalized services
            that cater to the specific needs of small businesses. From
            accounting and bookkeeping to financial planning and investment
            strategies, we've got you covered. We believe that every small
            business deserves access to professional financial guidance, and we
            are committed to being your trusted partner on your journey to
            financial success. Discover the difference that expert financial
            support can make for your small business.
          </p>
          <p>
            Join hands with K3 Finance and take control of your financial future
            today.
          </p>
        </div>
      </div>
    </>
  );
};
