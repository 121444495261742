import React from "react";

export const RealEstate = () => {
  return (
    <>
      <div
        className="bg-reho h-screen bg-cover items-center grid lg:grid-cols-1 xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 px-12 xl:px-16  lg:px-20  xl:py-16 lg:py-16 md:py-16 sm:py-10 sm:px-10 md:px-9  py-16 "
        // style={{ backgroundImage: `url(${myImage})` }}
      >
        <div className=" xl:text-center  lg:text-center md:text-center sm:text-center text-center xl:mt-32 lg:mt-20 md:mt-16 sm:mt-14 mt-9">
          <h1
            className=" font-extrabold k3com  text-[#E4F1FF] text-2xl lg:text-5xl xl:text-7xl md:text-3xl sm:text-3xl mb-10"
            data-aos="flip-left"
            data-aos-duration="1200"
          >
            K3 REALESTATE
          </h1>
          <p
            className="font-medium text-justify tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-sm md:text-sm xl:px-48 lg:px-2 md:px-12 sm:px-12 px-5  text-[#E4F1FF] "
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            At K3 Real Estate, we redefine property experiences with a
            commitment to excellence. Our portfolio spans a diverse range of
            properties, from luxurious residences to strategic commercial
            spaces. With a focus on innovation and personalized service, we
            guide clients through seamless transactions, ensuring their real
            estate goals are not just met but exceeded. Trust K3 Service to
            navigate the intricacies of the property market, providing
            unparalleled expertise and a tailored approach to meet
            your unique needs
          </p>
        </div>
      </div>
    </>
  );
};
