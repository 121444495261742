import React from "react";
// import HeroBackground from "./HeroBackground";
import myImage from "../Images/purple background.jpg";
import "../../src/hero.css";
export const HeroToper = () => {
  return (
    <>
      <div
        className="bg-vectorSolution h-screen bg-cover bg-[#5B3752] items-center  grid lg:grid-cols-1 xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 px-12 xl:px-16  lg:px-20  xl:py-16 lg:py-16 md:py-16 sm:py-10 sm:px-10 md:px-9  py-16 "
        // style={{ backgroundImage: `url(${myImage})` }}
      >
        <div className=" xl:text-center  lg:text-center md:text-center sm:text-center text-center xl:mt-32 lg:mt-20 md:mt-16 sm:mt-14 mt-9">
          <h1
            className=" font-extrabold k3com  text-[#472B40] text-2xl lg:text-5xl xl:text-7xl md:text-3xl sm:text-3xl mb-10"
            data-aos="flip-left"
            data-aos-duration="1200"
          >
            K3 COMPANY
          </h1>
          <p
            className="font-medium text-justify tracking-widest text-[10px] lg:text-sm lg:mt-5 md:mt-3 sm:mt-2 mt-3 xl:text-lg sm:text-sm md:text-sm xl:px-48 lg:px-2 md:px-12 sm:px-12 px-5  text-[#E4F1FF]"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            Welcome to K3 Company, where innovation meets excellence. As a
            dynamic and forward-thinking service company, we pride ourselves on
            delivering cutting-edge products and unparalleled services tailored
            to meet the evolving needs of our clients. Our commitment to
            quality, customer satisfaction, and continuous improvement sets us
            apart in the competitive landscape. With a team of dedicated
            professionals and a passion for pushing the boundaries of what's
            possible, we strive to provide not just products and services, but
            transformative solutions that empower our clients and drive success.
            Discover the difference with K3, where your goals become our
            mission.
          </p>
        </div>
      </div>
    </>
  );
};
