import React from "react";
import icoq1 from "../Images/HomeInterior/couch.png";
import icoq2 from "../Images/HomeInterior/bed.png";
import icoq3 from "../Images/HomeInterior/bowl.png";
import icoq4 from "../Images/HomeInterior/cabinet.png";
import icoq5 from "../Images/HomeInterior/staircase.png";
import icoq6 from "../Images/HomeInterior/renovation.png";

export const InteriorList = () => {
  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-4 bg-white py-32 px-2 ">
        <div
          className=" p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq1} alt="Image 1" className="w-20 h-20 icoo" />
          <h1 className="text-[#32094f] font-bold text-xl mt-3">
            Living/Dining Room
          </h1>
          <p className="py-5 text-center text-lg ">
            TV Unit, TV Back Panelling, Crockery Unit, Bar Unit, Bookshelf
          </p>
        </div>
        <div
          className="p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq2} alt="Image 1" className="w-20 h-20 icoo" />
          <h1 className="text-[#32094f] font-bold text-xl mt-3">Bedrooms</h1>
          <p className="py-5 text-center text-xl ">
            Wardrobes, TV Unit, Bed with Storage, Dressing Unit, Study Unit
          </p>
        </div>
        <div
          className=" p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq3} alt="Image 1" className="w-20 h-20 icoo" />
          <h1 className="text-[#32094f] font-bold text-xl mt-3">Kitchen</h1>
          <p className="py-5 text-center text-xl ">
            Countertops, Backsplashes, Accessories, Shutters, Storage
          </p>
        </div>
        <div
          className=" p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq4} alt="Image 1" className="w-20 h-20 icoo" />
          <h1 className="text-[#32094f] font-bold text-xl  text-center mt-3">
            Hidden Storage
          </h1>
          <p className="py-5 text-center text-xl ">
            Janitor Unit, Skirting Drawer, Pantry Pull Out, Appliance Garage,
            Hidden Bar Cabinet, Magic Corner
          </p>
        </div>
        <div
          className=" p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq5} alt="Image 1" className="w-20 h-20 icoo" />
          <h1 className="text-[#32094f] font-bold text-xl mt-3">
            Interior design
          </h1>
          <p className="py-5 text-center text-xl ">
            False Ceiling, Wall Panelling, Decor Accents, Lighting, Furnishing,
            Appliances
          </p>
        </div>
        <div
          className=" p-4 flex flex-col items-center justify-center"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <img src={icoq6} alt="Image 1" className="w-20 h-20 icoo" />
          <h1 className="text-[#32094f] font-bold text-xl mt-3">
            Home Renovation Services
          </h1>
          <p className="py-5 text-center text-xl ">
            Painting, Bathroom Remodelling, Tiling, Plumbing, Electrical, Civil
            Work, Deep Cleaning
          </p>
        </div>
      </div>
    </>
  );
};
