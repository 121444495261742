import React from "react";
import realEstate from "../Images/Travel/IMG_20151207_165956.jpg";
import catering from "../Images/Travel/right-front-three-quarter0.webp";
import Finance from "../Images/Travel/innova-crysta-2.jpg";
import Tours from "../Images/Travel/download.jpeg";

export const Traveshad = () => {
  return (
    <>
      <div class="flex flex-wrap justify-center bg-white xl:py-20 lg:py-20 md:py-12 sm:py-12 py-12 xl:px-12 lg:px-4 md:px-8 sm:px-12 px-12">
        <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-[#5B3752] text-xl mb-2 text-center">
              Swift Car Rental
            </div>
            <img class="w-full rounded-md" src={realEstate} alt="Card image" />
            <p class="text-gray-700 xl:text-base sm:text-base md:text-base text-sm   lg:text-base mt-5">
              K3 Travels provides Swift Dzire car rental in Chennai for local,
              outstation, and tour trips. Our Swift Dzire cars are provided for
              rental at the best price in Chennai and driven by a
              well-experienced driver.
            </p>
          </div>
        </div>
        <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-[#5B3752] text-xl mb-2 text-center">
              Etios Car Rental
            </div>
            <img class="w-full rounded-md" src={catering} alt="Card image" />
            <p class="text-gray-700 xl:text-base sm:text-base md:text-base text-sm   lg:text-base mt-5">
              K3 Travels provides Etios car for rent for the customers who look
              for car rental at affordable prices. So, why wait? hire Etios for
              Chennai local trips and Chennai to outstation trips at a
              budget-friendly price.
            </p>
          </div>
        </div>
        <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-[#5B3752] text-xl mb-2 text-center">
              Innova Car Rental
            </div>
            <img class="w-full rounded-md" src={Finance} alt="Card image" />
            <p class="text-gray-700 xl:text-base sm:text-base md:text-base text-sm   lg:text-base mt-5">
              We at K3 Travels provide 6 and 7-seater Innova cars for rent in
              Chennai for local and outstation trips. Our Innova cars are well
              maintained and driven by industry well-experienced drivers. Call
              us to book now.
            </p>
          </div>
        </div>
        <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-[#5B3752] text-xl mb-2 text-center">
              Honda car Rental
            </div>
            <img class="w-full rounded-md" src={Tours} alt="Card image" />
            <p class="text-gray-700 xl:text-base sm:text-base md:text-base text-sm   lg:text-base mt-5">
              K3 Travels offers Honda car rentals in Chennai, catering to your
              local, outstation, and tour trip needs. Our fleet of Honda cars is
              available for rent at competitive prices, accompanied by skilled
              and experienced drivers to ensure a comfortable and safe journey.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
