import React from "react";

export const Travtwo = () => {
  return (
    <>
      {/* <div class="bg-gray-100 flex justify-center items-center h-screen"> */}
      <div class="flex flex-col lg:flex-row justify-between bg-sha bg-cover bg-no-repeat p-10 lg:p-10 h-screen shadow-md rounded-lg">
    <div class="flex justify-center items-center lg:w-1/2">
        <div class="w-full leading-loose text-white" data-aos="fade-right">
            <h1 class="text-2xl lg:text-3xl font-bold mb-6 lg:mb-10 xl:text-3xl sm:text-3xl md:text-3xl">
                Lord Venkateswara temple, Tiruchanur Padmavathi Amma Vari temple, Tirupati Govintharaja Perumal temple.
            </h1>
            <p className="xl:text-lg sm:text-base md:text-base text-sm   lg:text-xl">
                At Innova Car Rental K3 from Chennai Airport to Tirupati same day Charges Calculated per Km rate and 2 Days or 3 Days tirupati trip Per Day Minimum 250 kms Basis must be Calculated. From Chennai Airport to Tirupati Tirumala in Andhra It is Very good Option to travel with a Family and Small Group of 3 to 7 People in Innova. We are also connected with Many Customers in worldwide and offering Quality Toyota Innova Car Rental for Chennai Sightseeing tour, Picnic, Outing, Weekend tours, Pilgrimage Package, Hill Station tours at Innova is Best Comfort with Safest Journey.
            </p>
        </div>
    </div>
    <div class="hidden lg:block lg:w-1/2"></div>
</div>

    </>
  );
};
