import React from "react";
import chennai from "../Images/Catering/delicious-indian-food-tray_23-2148723505.avif";
import chennai2 from "../Images/Catering/mutton.jpg";

export const CateringHead = () => {
  return (
    <>
      <div className="grid   grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4 sm:grid-cols-1 bg-white py-20 px-36 xl:px-32 lg:px-24 md:px-52 sm:px-40">
        <div
          className="flex items-center justify-center"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div>
            <h6 className="text-2xl xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl font-bold text-[#472B40]">
              Vegetarian catering services
            </h6>
            <p className="mt-4 ">
              Featuring some of the most varied cuisines from all South Indian
              cooking traditions, K3 Caterers, one of the best vegetarian
              catering services in Chennai, can provide. We ensure that our
              clients in and around Chennai receive the best catering services
              possible by having our catering professionals understand the needs
              of our guests and plan their events accordingly.
              <br></br>
              <br></br>
              Apart from our vast menu offering world-class cuisine, we also
              specialize in various Indian types of cooking. Perfect vegan
              catering services in Chennai that provide a variety of unusual
              flavors under the imaginative direction of skilled chefs have
              delighted us and our clients. <br></br>
              <br></br>
              Our innovative vegetarian catering menu has something for
              everybody. We have delighted our clients with impeccable veg
              catering services in chennai that offer an array of eclectic
              flavours under the creative guidance of talented chefs.
            </p>
          </div>
        </div>
        <div data-aos="fade-left" data-aos-duration="1000">
          <img
            src={chennai}
            alt="Deer"
            class="w-full h-full object-cover rounded-xl"
          />
        </div>
      </div>


      <div className="grid   grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4 sm:grid-cols-1 bg-white py-20 px-36 xl:px-32 lg:px-24 md:px-52 sm:px-40">
        <div data-aos="fade-right" data-aos-duration="1000">
          <img src={chennai2} alt="Deer" class="w-full h-full rounded-xl" />
        </div>
        <div
          className="flex items-center justify-center"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div>
            <h6 className="text-2xl xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl font-bold text-[#472B40]">
              Non Vegetarian catering services
            </h6>
            <p className="mt-4 ">
              Are you looking for non-vegetarian catering services in Chennai so
              you can get everything you need catered for your wedding? With a
              team of highly skilled professionals, we are the top
              non-vegetarian catering service. We provide fantastic Non-Veg
              Catering Services packages to accommodate groups of all sizes as
              we strive for perfection for every client, one event at a time. We
              don't just offer you samples of our catering; we aim to outperform
              our rivals in this area.
              <br></br>
              <br></br>
              We have amazing Non-Veg Catering Services packages to cater to
              groups of all sizes as we strive for perfection for each client,
              one event at a time.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
