import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../Images/logo/k3logo.png";
import React, { useState } from "react";

const navigation = [
  {
    name: "Home",
    href: "/",
    current: false,
  },

  {
    name: "Services",
    href: "#",
    current: false,
    submenus: [
      {
        name: "Tours and Travels",
        href: "../services/travels",
        current: false,
        openInNewTab: true,
      },
      {
        name: "Small Finance",
        href: "../services/finance",
        current: false,
        openInNewTab: true,
      },
      {
        name: "Catering",
        href: "../services/catering",
        current: false,
        openInNewTab: true,
      },
      {
        name: "Real Estate",
        href: "../services/realestate",
        current: false,
        openInNewTab: true,
      },
      {
        name: "Home Intertior",
        href: "../services/interiordesign",
        current: false,
        openInNewTab: true,
      },
    ],
  },
  { name: "About Us", href: "../Aboutus", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavbarSka() {
  const [showSubmenu, setShowSubmenu] = useState(false);

  const handleToggleSubmenu = () => {
    setShowSubmenu(!showSubmenu);
  };

  return (
    <Disclosure
      as="nav"
      className="bg-[#3e0046] fixed top-10 left-20 xl:w-fit right-20 z-50 backdrop-blur-md bg-opacity-5 rounded-full"
      // className="bg-[#3e0046]  fixed top-0 left-0 w-full z-50"
    >
      {({ open }) => (
        <>
          <div className="  mx-auto  px-2 xl:px-0  sm:px-6 lg:px-0   ">
            <div className="relative flex xl:h-16 lg:h-20 md:h-16 sm:h-14 h-16 items-center  justify-between xl:p-10 lg:p-5 md:p-2  ">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-[#79C942] hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch  sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/">
                    <img
                      className="xl:h-14 md:h-6 lg:h-8 sm:h-6 h-10  w-auto rounded-md"
                      src={logo}
                      alt="Your Company"
                    />
                  </a>
                </div>

                <div className="flex items-center ">
                  <div className="hidden sm:ml-4 sm:block justify-center xl:ml-[850px] lg:ml-48 md:ml-4">
                    <div className="flex space-x-2">
                      {navigation.map((item) => (
                        <div key={item.name} className="relative group">
                          <a
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "text-white "
                                : "text-[#624059] transition duration-200 hover:text-[#FFD23F] ",
                              "rounded-md xl:px-3 lg:px-2 md:px-2 py-2 xl:text-xl lg:text-base md:text-xs sm:text-xs font-bold"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </a>

                          {item.submenus && (
                            <div className="hidden group-hover:block absolute left-0  mt-1 space-y-2 w-48 text-center bg-white text-[#4D4D4D] rounded-lg shadow-lg transition-all duration-300 ease-in-out">
                              {item.submenus.map((submenu) => (
                                <a
                                  key={submenu.name}
                                  href={submenu.href}
                                  target={
                                    submenu.openInNewTab ? "_blank" : "_self"
                                  }
                                  rel="noopener noreferrer"
                                  className={classNames(
                                    submenu.current
                                      ? "bg-[#4CAF4F] text-white"
                                      : "text-[#4D4D4D] hover:bg-[#52364d] hover:text-white",
                                    "block rounded-md px-4 py-2 xl:text-lg lg:text-lg md:text-base sm:text-sm font-medium transition-all duration-300 ease-in-out"
                                  )}
                                  aria-current={
                                    submenu.current ? "page" : undefined
                                  }
                                >
                                  {submenu.name}
                                </a>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="flex  items-center font-semibold">
                <div className="relative inline-block">
                  <button
                    className="glow-button font-bold mr-4 flex items-center xl:px-4 xl:py-2 lg:px-5 lg:py-3 md:px-2 md:py-2 sm:px-3 sm:py-2 px-3 py-2 text-white border border-[#79C942] transition duration-200 rounded-md xl:text-sm lg:text-sm md:text-sm sm:text-xs text-[10px]"
                    // onClick={handleToggleSubmenu}

                    onClick={() =>
                      window.open("https://webcheck.K3Company.com/", "_blank")
                    }
                  >
                    Free Scan
                  </button>
                </div>

                <button className="glow-button font-bold xl:px-4 xl:py-2 lg:px-5 lg:py-3 md:px-2 md:py-2 sm:px-3 sm:py-2 px-3 py-2 text-white bg-[#3C7317]  rounded-md xl:text-sm lg:text-sm md:text-sm sm:text-xs text-[10px] ">
                  <a href="demo"> Book Demo</a>
                </button>
            
              </div> */}
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden ">
            <div className="space-y-1 px-2  pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-white hover:bg-[#3C7317] hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium transition duration-200"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
