import React from "react";
import realEstate from "../Images/house-1836070_1280.jpg";
import catering from "../Images/food.jpg";
import Finance from "../Images/plant-growing-coins-glass-jar-money-green-grass_155003-5432.avif";
import Tours from "../Images/young-family-enjoying-their-trip_23-2149224391.avif";
import Design from "../Images/3d-rendering-loft-luxury-living-room-with-shelf-near-dining-table_105762-2052.avif";

import "aos/dist/aos.css";

export const SecurityCenter = () => {
  return (
    <>
      {/* <div className="bg-[#18091C]  grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 py-10 lg:py-10 xl:py-16 md:py-16 sm:py-16 xl:px-10 lg:px-20 md:px-16 px-7 sm:px-20 lg:gap-5 md:gap-5  xl:gap-5 sm:gap-9 gap-5"></div> */}

      <div className="bg-white  xl:py-14  lg:py-10 md:py-8 sm:py-5 py-5 lg:px-36 sm:px-16 xl:px-28 md:px-14 px-12">
        <h3 className="text-2xl xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl  font-bold text-center  my-14 ">
          <span
            className="k3serv "
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            Plan Your Dream
          </span>
        </h3>

        <p className=" bg-white   font-normal tracking-wider leading-loose text-center text-[12px] xl:text-xl lg:text-lg md:text-base sm:text-xs">
          At K3 Connect, we believe in fostering a life full of well-rounded
          experiences. We are dedicated to providing exceptional service,
          personalized attention, and a commitment to your satisfaction. Let us
          be your partner in creating a life filled with adventure, beauty, and
          financial security.
        </p>
      </div>

      <div className="flex flex-wrap justify-center font-bold text-sm bg-white py-24 xl:px-32 lg:px-24 md:px-16 sm:px-12 px-20">
  <div className="w-3/4 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5  p-4" data-aos="fade-right" data-aos-duration="500">
    <img src={realEstate} alt="Image 1" className="rounded-md buit " />
    <p className="py-5 text-center">Premium Real Estate</p>
  </div>
  <div className="w-3/4 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 p-4" data-aos="fade-down-right">
    <img src={catering} alt="Image 2" className="rounded-md buit" />
    <p className="py-5 text-center">Taste the Moment</p>
  </div>
  <div className="w-3/4 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 p-4" data-aos="fade-down">
    <img src={Finance} alt="Image 3" className="rounded-md buit" />
    <p className="py-5 text-center">MicroMint Finance</p>
  </div>
  <div className="w-3/4 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 p-4" data-aos="fade-down-left">
    <img src={Tours} alt="Image 4" className="rounded-md buit" />
    <p className="py-5 text-center">Epic Journeys Await</p>
  </div>
  <div className="w-3/4 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 p-4" data-aos="fade-left" data-aos-duration="500">
    <img src={Design} alt="Image 5" className="rounded-md buit" />
    <p className="py-5 text-center">Dream Home Design</p>
  </div>
</div>

    </>
  );
};
