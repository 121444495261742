import React from "react";
import tile1 from "../Images/HomeInterior/living1.jpg";
import tile2 from "../Images/HomeInterior/living2.jpg";
import tile3 from "../Images/HomeInterior/living3.jpg";
import tile4 from "../Images/HomeInterior/living4.avif";
import Finance from "../Images/Travel/innova-crysta-2.jpg";
import Tours from "../Images/Travel/download.jpeg";

export const InteriorLiving = () => {
  return (
    <>
      <div>
      <div className="bg-[#472B40] bg-fixed">

        <h6 className="text-3xl text-center font-bold text-white p-10">
          Living Room Interiors For A Fabulous First Impression
        </h6>
        </div>
        <div
          id="cards_landscape_wrap-2"
          className="text-center bg-white px-10"
        >
          <div className="container pb-20 ">
            <div className="flex flex-wrap justify-center -mx-4">
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile1}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <h6 className="text-xl">
                          A Living room with a Tv unit and Book shelf
                        </h6>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile2}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <h6 className="text-xl font-bold">
                          A Living room with a pooja mandir Unit
                        </h6>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile3}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <h6 className="text-xl font-bold ">
                          A Living Unit with a free-standing book unit
                        </h6>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile4}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <h6 className="text-xl font-bold">
                          A Living Unit with a Relaxing green space
                        </h6>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
