import React from "react";
import realEstate from "../Images/RealEstate/e1.webp";
import catering from "../Images/RealEstate/e3.webp";
import Finance from "../Images/RealEstate/e2.jpg";
import real from "../Images/RealEstate/e4.webp";

export const Realproper = () => {
  return (
    <>
      {/* <p className=" bg-white text-center text-violet text-2xl lg:text-3xl xl:text-3xl md:text-3xl sm:text-3xl ">
        Here’s how we make our finance offerings Fast, Flexible and Friendly!
      </p> */}
      <div className="bg-white py-20 px-20">
        <h1
          className=" font-bold  text-center text-[#472B40] text-2xl lg:text-4xl xl:text-4xl md:text-3xl sm:text-3xl pt-12 pb-5"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          Achieve Financial Success with K3's Fintech Strategic Benefits
        </h1>
        <div
          className="flex justify-center text-base"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <ul>
            <li>
              Examine the home's interior and exterior to document its current
              state. Then, calculate how much rent to charge. Finally, advertise
              the property to possible tenants.
            </li>
            <li>
              We help you relax by selecting a tenant who meets your
              requirements and has no record of crime based on their past
              experiences, among other things.
            </li>
            <li>
              With the help of our services, such as a suitable tenancy
              agreement and legal agreement, you may discover what true
              convenience means without any difficulties.
            </li>
            <li>
              We will collect and quickly pay your utility bills, including
              those for electricity, water, and taxes on property.
            </li>
            <li>
              When you require maintenance or have problems with the property,
              you should expect nothing less than the best. Among others, we'll
              find carpenters, painters, electricians, and plumbers.
            </li>
          </ul>
        </div>

        <div class="flex flex-wrap justify-center bg-white xl:py-10">
          <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 rounded overflow-hidden shadow-lg m-4">
            <div
              class="px-6 py-6"
              data-aos="zoom-in-down"
              data-aos-duration="800"
            >
              <img class="w-full" src={realEstate} alt="Card image" />
            </div>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 rounded overflow-hidden shadow-lg m-4">
            <div
              class="px-6 py-6"
              data-aos="zoom-in-down"
              data-aos-duration="800"
            >
              <img class="w-full" src={catering} alt="Card image" />
            </div>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 rounded overflow-hidden shadow-lg m-4">
            <div
              class="px-6 py-6"
              data-aos="zoom-in-down"
              data-aos-duration="800"
            >
              <img class="w-full" src={Finance} alt="Card image" />
            </div>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 rounded overflow-hidden shadow-lg m-4">
            <div
              class="px-6 py-6"
              data-aos="zoom-in-down"
              data-aos-duration="800"
            >
              <img class="w-full" src={real} alt="Card image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
