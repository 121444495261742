import React from "react";

import icoq1 from "../Images/k3/bi_people-fill-1.svg";
import icoq2 from "../Images/k3/bi_people-fill-2.svg";
import icoq3 from "../Images/k3/bi_people-fill.svg";
import icoq4 from "../Images/k3/mdi_account-online.svg";
import AOS from "aos";
import "aos/dist/aos.css";

export const Environments = () => {
  return (
    <>
      <div className=" ">
        <div className="bg-[#472B40] bg-cover bg-fixed text-white xl:py-28 lg:py-12 md:py-10 sm:py-10 py-10  xl:px-24 lg:px-10 md:px-14 sm:px-9 px-12 ">
          <h3 className=" text-xl xl:text-5xl  lg:text-4xl md:text-4xl sm:text-2xl  font-bold text-center   mb-4   ">
            WHY CHOOSE US
          </h3>
          <h1 className=" xl:text-3xl lg:text-xl md:text-2xl font-semibold text-center mb-5">
            We Provide True Technology Solutions
          </h1>

          <p className="  tracking-wider text-center text-[12px] xl:text-lg lg:text-base md:text-base sm:text-xs text-xs ">
            For any Digital Transformation Needs, Truetech Ensures Customized
            Approach – The Right Technology Solutions – Customer Experience as
            Priority
          </p>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 bg-white py-7 xl:py-20 lg:py-12 md:py-12 sm:py-12 xl:px-32 lg:px-16 md:px-16  px-12 text-base">
          <div
            class="p-4 flex flex-col items-center justify-center"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
          >
            <img src={icoq1} alt="Image 1" class="w-auto h-auto icoo" />
            <p class="py-5 text-center  font-semibold ">
              End to End - Agile Methodology
            </p>
          </div>
          <div
            class="p-4 flex flex-col items-center justify-center"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
          >
            <img src={icoq2} alt="Image 2" class="w-auto h-auto icoo" />
            <p class="py-5 text-center  font-semibold">Fullscope Delivery</p>
          </div>
          <div
            class="p-4 flex flex-col items-center justify-center"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
          >
            <img src={icoq3} alt="Image 3" class="w-auto h-auto icoo" />
            <p class="py-5 text-center  font-semibold">Strong Governance</p>
          </div>
          <div
            class="p-4 flex flex-col items-center justify-center"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
          >
            <img src={icoq4} alt="Image 4" class="w-auto h-auto icoo" />
            <p class="py-5 text-center  font-semibold">Lifetime Support</p>
          </div>
        </div>
      </div>
    </>
  );
};
