import React from "react";

export const Realbutt = () => {
  return (
    <>
  <div className="bg-realEstateback bg-cover text-center px-10 py-24 bg-fixed">
  <h1 className="font-bold text-center text-white text-2xl lg:text-5xl xl:text-4xl md:text-3xl sm:text-3xl mb-4">
    NEED MORE INFO? CONTACT US NOW!
  </h1>
  <p className="text-center text-white text-lg lg:text-3xl xl:text-3xl md:text-3xl sm:text-3xl mb-3">
    "Share Your Queries, Make Informed Decisions."
  </p>
  <button className="bg-[#472B40] hover:bg-white hover:text-[#472B40] text-white text-lg lg:text-xl font-bold py-3 px-6 rounded transition ease-out duration-200">
    Contact Us
  </button>
</div>

    </>
  );
};
