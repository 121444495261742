import React from "react";

export const TermsAndCondition = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4 sm:grid-cols-1 bg-white py-20 px-36 xl:px-32 lg:px-24 md:px-52 sm:px-40">
        <div
          className="flex items-center justify-center"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div>
            <h1
              className="text-center font-extrabold text-3xl lg:text-3xl xl:text-3xl md:text-3xl sm:text-3xl mb-8 "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <span className="k3serv">Terms and Conditions</span>
            </h1>

            <p className="mt-4 ">
              ✔ Minimum age 21 years with valid driving license (national or
              international) All Rentals on 24 hours/1 day basis.
              <br></br>
              <br></br>✔ Security deposit of Rs. 5,000/- as cash for all cars
              will be collected in advance.
              <br></br>
              <br></br>✔ Incase of damage or scratch to the car or theft of any
              accessories it will be forfeited. Repair cost will be borne by the
              renter.
            </p>
          </div>
        </div>
        <div data-aos="fade-left" data-aos-duration="1000">
          <div>
            <h1
              className="text-center font-extrabold text-3xl lg:text-3xl xl:text-3xl md:text-3xl sm:text-3xl mb-8 "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <span className="k3serv">Proof need to Submit</span>
            </h1>

            <p className="mt-4 text-bold">
              ✔ PRIMARY ID PROOF
              <br></br>
              Original Riginal Passport or Original Ration Card Mandatory.
              <br></br>
              <br></br>✔ SECONDARY ID PROOF
              <br></br>
              Driving Licence / Voter ID / Adhar Card / PAN Card / OFF ID / 2
              Cheque Leaf.
              <br></br>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="bg-white py-20 xl:px-28 lg:px-48 md:px-28 sm:px-40 px-6">
        <h1
          className="text-center  font-extrabold  text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl mb-8 "
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <span className="k3serv">Terms and Conditions</span>
        </h1>

        <div
          className="font-normal text-justify  lg:text-base lg:mt-5 md:mt-3 sm:mt-2  xl:text-base sm:text-xs md:text-base text-base  "
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <p className="mb-2">
            ✔ Minimum age 21 years with valid driving license (national or
            international) All Rentals on 24 hours/1 day basis.
            <br></br>✔ Security deposit of Rs. 5,000/- as cash for all cars will
            be collected in advance.
            <br></br>✔ Incase of damage or scratch to the car or theft of any
            accessories it will be forfeited. Repair cost will be borne by the
            renter.
          </p>

          <p className="mb-2">
            Our experienced team is dedicated to providing personalized services
            that cater to the specific needs of small businesses. From
            accounting and bookkeeping to financial planning and investment
            strategies, we've got you covered. We believe that every small
            business deserves access to professional financial guidance, and we
            are committed to being your trusted partner on your journey to
            financial success. Discover the difference that expert financial
            support can make for your small business.
          </p>
          <p>
            Join hands with K3 Finance and take control of your financial future
            today.
          </p>
        </div>
      </div> */}
    </>
  );
};
