import React from "react";
import chennai from "../Images/Travel/temple-2033409.jpg";

export const Travteo = () => {
  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-4 bg-white py-10 lg:py-20 px-10 xl:px-28 lg:px-36 md:px-28 sm:px-28">
        <div className=" ">
          <img src={chennai} alt="Deer" class="w-full h-full rounded-2xl" />
        </div>
        <div className="flex flex-wrap items-center justify-center">
          <div>
            <h6 className="xl:text-lg sm:text-base md:text-base text-xl    lg:text-xl  font-bold text-[#5B3752]">
              "Explore Divine Journeys and Scenic Retreats - Tailored Tour
              Packages by K3 Travels from Chennai"
            </h6>
            <p className="mt-4 lg:mt-6  xl:text-lg sm:text-base md:text-base text-sm lg:text-xl ">
              {" "}
              At K3 Travels, we offer tour packages from Chennai to a variety of
              cities across the globe. We at Chennai Travels can create a temple
              tour package for you, whether it's a visit to one of the
              well-known temples, such as Tanjore Belt, the Balaji Temple in
              Tirupati, Thiruvannamalai Temple, Golden Temple in Vellore,
              Meenakshi Sundareswarar Temple in Madurai, Chidambaram Temple,
              Kamakshi Amman Temple in Kanchipuram, or any other temple of your
              choice.
              {/* <br></br>
              <br></br> */}
              Contacting K3 Travels know when you would like to relax in beach
              resorts in Pondycherry, Mahabalipuram, or ECR, or in hill areas
              like Ooty, Kodai, or Yercaud. We manage all of your travel
              requirements.
              <br></br>
              <br></br>
              Our pricing approach is open and honest, with no additional fees.
              As an outcome, you may relax about your rentals and enjoy your
              trip without bothering about paying extra.
              {/* The best planners on
              the team can help you get to the greatest places in India. Pick
              the favorite trip package that meets all the prerequisites and
              book your place. */}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
