import React from "react";
import realEstate from "../Images/RealEstate/r1.jpg";
import catering from "../Images/RealEstate/r2.jpg";
import Finance from "../Images/RealEstate/close-up-woman-s-hand-giving-house-key-man-wooden-table_23-2148204009.jpg";

export const Realcard = () => {
  return (
    <>
      <div className="bg-white py-10">
        <h1
          className=" font-bold  text-center text-[#472B40] text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl mb-5 "
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          K3’s Advantages
        </h1>
        <p
          className="  text-center text-violet text-2xl lg:text-3xl xl:text-3xl md:text-3xl sm:text-3xl "
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          Fast, Flexible and Friendly!
        </p>
      </div>

      <div class="flex flex-wrap justify-center bg-white py-20 ">
        <div class="w-2/3 md:w-1/2 lg:w-1/4 rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-xl mb-2 text-center text-[#472B40]">
              Owner
            </div>
            <img
              class="w-full h-full object-cover"
              src={realEstate}
              alt="Card image"
            />
            <p class="text-gray-700 text-center text-base mt-5">
              Foreign citizens and other individuals who rarely use their
              property and require management for tenant relations, rentals, and
              other property-related maintenance.
            </p>
          </div>
        </div>
        <div class="w-2/3 md:w-1/2 lg:w-1/4 rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-xl mb-2 text-center text-[#472B40]">
              Tenant
            </div>
            <img
              class="w-full h-full object-cover"
              src={catering}
              alt="Card image"
            />
            <p class="text-gray-700 text-center text-base mt-5">
              We will evaluate any maintenance issues you may have or personal
              ones and manage your concerns right away.
            </p>
          </div>
        </div>
        <div class="w-2/3 md:w-1/2 lg:w-1/4 rounded overflow-hidden shadow-lg m-4">
          <div class="px-6 py-6">
            <div class="font-bold text-xl mb-2 text-center text-[#472B40]">
              Rentals
            </div>
            <img
              class="w-full h-full object-cover"
              src={Finance}
              alt="Card image"
            />
            <p class="text-gray-700 text-center text-base mt-5">
              For tenants in need of property at a fair rent, our rental service
              is a wonderful benefit.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
