import React from "react";
import c1 from "../Images/Catering/meal-52075.jpg";
import c2 from "../Images/Catering/chicken-2451482.jpg";
import c3 from "../Images/Catering/biriyani-7599454.jpg";
import c4 from "../Images/Catering/cake-8233676.jpg";
import c5 from "../Images/Catering/biryani-1.jpg";
import c6 from "../Images/Catering/cupcake-2749204.jpg";
import c7 from "../Images/Catering/mandi-6048376.jpg";
import c8 from "../Images/Catering/rice-6324799.jpg";

export const Catering = () => {
  return (
    <>
      <div className="justify-center bg-foodie bg-cover bg-[#5B3752] py-36">
        <div class="containertest py-10">
          <div>
            <img src={c1} alt="bike" />
          </div>

          <div>
            <img src={c2} alt="rose" />
          </div>

          <div>
            <img src={c3} alt="camera" />
          </div>

          <div>
            <img src={c4} alt="road" />
          </div>
        </div>
        <div class="containertest">
          <div>
            <img src={c5} alt="bike" />
          </div>

          <div>
            <img src={c6} alt="rose" />
          </div>

          <div>
            <img src={c7} alt="camera" />
          </div>

          <div>
            <img src={c8} alt="road" />
          </div>
        </div>

        {/* <div class="containerimg">
            <div class="boximg">
              <div class="imgBx">
                <img
                  src={c1}
                  class="object-cover w-full h-full"
                  alt="Image 1"
                />
              </div>
            </div>
            <div class="boximg">
              <div class="imgBx">
                <img
                  src={c2}
                  class="object-cover w-full h-full"
                  alt="Image 2"
                />
              </div>
            </div>
            <div class="boximg">
              <div class="imgBx">
                <img
                  src={c3}
                  class="object-cover w-full h-full"
                  alt="Image 3"
                />
              </div>
            </div>
            <div class="boximg">
              <div class="imgBx">
                <img
                  src={c4}
                  class="object-cover w-full h-full"
                  alt="Image 4"
                />
              </div>
            </div>
          </div> */}

        {/* <div className="w-1/5 p-4">
          <img
            src={realEstate}
            alt="Image 1"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Premier Real Estate</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={catering}
            alt="Image 2"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Taste the Moment</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={Finance}
            alt="Image 3"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">MicroMint Finance</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={Tours}
            alt="Image 4"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Epic Journeys Await</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={Design}
            alt="Image 5"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Dream Home Design</p>
        </div> */}
      </div>
      <div className="bg-white py-20 px-20 ">
        <h1
          className=" font-extrabold bg-white text-[#32094f]  text-center text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl mb-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <span className="k3serv"> K3 Catering</span>
        </h1>
        <h1 className="text-3xl mt-7 text-center text-[#FFD23F] font-semibold">
          Enjoy our Food Experience
        </h1>
        <p
          className=" bg-white text-[#32094f]  text-center   lg:mt-5 md:mt-3 sm:mt-2   mb-3"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          Welcome to K3 Catering services, where culinary excellence meets
          unforgettable events. We take pride in crafting exceptional dining
          experiences for every occasion.
        </p>{" "}
        <p className="text-center text-[#32094f] ">
          Whether it’s a formal dinner, buffet, finger foods, or BBQ get
          together, your daughter’s wedding, anniversary party, corporate event,
          we can provide you with everything you’ll need at a price that meets
          your budgetary needs.
        </p>
      </div>
    </>
  );
};
