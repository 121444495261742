import cor from "../Images/Catering/affair-1238434.jpg";
import birthday from "../Images/Catering/cake-8470014.jpg";
import wedding from "../Images/Catering/dana-point-5958631.jpg";
import small from "../Images/Catering/muffins-283640.jpg";

export const CateringTab = () => {
  return (
    <>
      <div class="bg-white px-40">
        <section id="our_menu" class="py-20">
          <div class="container">
            <div class="row">
              <div class="tab-content col-lg-12" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="breakfast"
                  role="tabpanel"
                  aria-labelledby="breakfast-tab"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <div class="single_menu flex items-center relative mb-20 transition duration-300 hover:overflow-visible">
                        <img
                          src={cor}
                          alt="burger"
                          class="w-300 absolute h-150 clip-path-[0% 0%,75% 0%,100% 50%,75% 100%,0% 100%] transition duration-300 border border-gray-300 rounded-5"
                        />
                        <div class="menu_content pl-20">
                          <h4 class="text-30 font-semibold border-b border-dashed border-gray-300 mb-8 text-purple-900 capitalize">
                            Corporate Events
                            <span class="text-25 font-bold italic float-right"></span>
                          </h4>
                          <p class="font-light text-16 leading-loose text-gray-700">
                            While you take a break from work, we provide the
                            best tasting experience with a variety of
                            crowd-pleasing platters.
                          </p>
                        </div>
                      </div>

                      <div class="single_menu flex items-center relative mb-20 transition duration-300 hover:overflow-visible">
                        <img
                          src={birthday}
                          alt="black coffee"
                          class="w-300 absolute h-150 clip-path-[0% 0%,75% 0%,100% 50%,75% 100%,0% 100%] transition duration-300 border border-gray-300 rounded-5"
                        />
                        <div class="menu_content pl-20">
                          <h4 class="text-30 font-semibold border-b border-dashed border-gray-300 mb-8 text-purple-900 capitalize">
                            Birthday parties
                            <span class="text-25 font-bold italic float-right"></span>
                          </h4>
                          <p class="font-light text-16 leading-loose text-gray-700">
                            Delicious cuisine is a must for each occasion, large
                            or little. Make a reservation with K3 Catering
                            immediately to feed your loved ones delicious food.
                            We guarantee that they will rave about your
                            wonderful hospitality everywhere they go.
                          </p>
                        </div>
                      </div>

                      <div class="single_menu flex items-center relative mb-20 transition duration-300 hover:overflow-visible">
                        <img
                          src={wedding}
                          alt="fried rice"
                          class="w-300 absolute h-150 clip-path-[0% 0%,75% 0%,100% 50%,75% 100%,0% 100%] transition duration-300 border border-gray-300 rounded-5"
                        />
                        <div class="menu_content pl-20">
                          <h4 class="text-30 font-semibold border-b border-dashed border-gray-300 mb-8 text-purple-900 capitalize">
                            Weddings
                            <span class="text-25 font-bold italic float-right"></span>
                          </h4>
                          <p class="font-light text-16 leading-loose text-gray-700">
                            To make the day even more memorable for you and your
                            guests, we offer inventive wedding catering services
                            together with delicious, freshly-sourced cuisine.
                          </p>
                        </div>
                      </div>

                      <div class="single_menu flex items-center relative mb-20 transition duration-300 hover:overflow-visible">
                        <img
                          src={small}
                          alt="meat"
                          class="w-300 absolute h-150 clip-path-[0% 0%,75% 0%,100% 50%,75% 100%,0% 100%] transition duration-300 border border-gray-300 rounded-5"
                        />
                        <div class="menu_content pl-20">
                          <h4 class="text-30 font-semibold border-b border-dashed border-gray-300 mb-8 text-purple-900 capitalize">
                            Small Events
                            <span class="text-25 font-bold italic float-right"></span>
                          </h4>
                          <p class="font-light text-16 leading-loose text-gray-700">
                            Good food is an integral part of every big/small
                            event. Book us now to serve your kith and kin with
                            some incredible food and we are sure that they will
                            talk about your incredible hospitality everywhere
                            they go.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
