import React from "react";

export const Travels = () => {
  return (
    <>
      <div
        className="bg-tro bg-contain bg-no-repeat bg-white items-center  grid lg:grid-cols-1 xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 px-12 xl:px-16 lg:px-20  xl:py-16 lg:py-16 md:py-16 sm:py-10 sm:px-10 md:px-9  py-16 "
        // style={{ backgroundImage: `url(${myImage})` }}
      >
        <div className=" xl:text-center  lg:text-center md:text-center sm:text-center text-center xl:mt-[500px] lg:mt-96 md:mt-72 sm:mt-56 mt-40">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            <h3 className=" text-2xl xl:text-5xl lg:text-5xl md:text-5xl sm:text-4xl  font-bold text-center   mb-5   ">
              <span className="k3serv"> K3 TRAVELS</span>
            </h3>
          </div>
          <p
            className="font-normal text-justify  text-xs lg:text-base lg:mt-5 md:mt-3 sm:mt-2  xl:text-lg sm:text-base md:text-base  text-black"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            Our K3 travels car rental service in Chennai is successful because
            of our outstanding and truthful service to our customers since time
            is never-ending. Being a leading car travels in Chennai, we at
            Chennai Travels make sure that your trip is safe with our
            well-maintained and experienced drivers. We rent out budget-friendly
            cars with 4 to 7-seaters from the models Maruti Swift Dzire, Toyota
            Etios, and Toyota Innova Crysta. Now, it’s your turn to call and
            hire cars from Chennai Travels either for your Chennai city trip or
            Chennai to Outstation trip.
            {/* Looking to hire a car for corporate
            events or weddings? Check out our luxury car rental service. */}
          </p>
        </div>
      </div>
    </>
  );
};
