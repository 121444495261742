import React from "react";

export const FinanceList = () => {
  return (
    <>
      <div class="bg-cover bg-no-repeat bg-fixed bg-hlo bg-[#472B40] brightness-90 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 gap-4  px-16  py-16">
        <div class="backdrop-saturate-200 bg-white/30 text-white rounded-lg shadow-md px-4 py-8  transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl text-center  mb-2">
            Holistic Financial Guidance
          </h3>
          <p class="text-sm">
            At K3's Finance, we go beyond numbers, providing comprehensive
            financial guidance for small businesses seeking success.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30 text-white  rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl text-center mb-2">
            Customized Finance Solutions
          </h3>
          <p class="text-sm">
            Explore aur diverse range of finance options designed to meet the
            unique needs of your small business, ensuring flexibility and
            convenience.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30 text-white  rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl text-center mb-2">
            Expert Finance Advisors
          </h3>
          <p class="text-sm">
            Access a team of experienced finance advisors dedicated to
            understanding your business goals and navigating you through sound
            financial strategies.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30 text-white  rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl text-center mb-2">
            Strategic Financial Planning
          </h3>
          <p class="text-sm">
            From budgeting to investment strategies, we offer strategic
            financial planning to empower your business with a solid financial
            foundation.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30 text-white  rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl text-center mb-2">
            Data Security Assurance
          </h3>
          <p class="text-sm">
            Rest easy knowing your sensitive Information is safeguarded with
            state-of-the-art encryption, making data security our top priority.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30 text-white  rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl text-center mb-2">
            Fast and Efficient Processing
          </h3>
          <p class="text-sm">
            Time is money. Benefit from our streamlined processes, ensuring
            swift and efficient handling of your Financial transactions and loan
            applications.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30 text-white  rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl text-center mb-2">
            Customer Centric Approach
          </h3>
          <p class="text-sm">
            Our customer centric philosophy means your satisfaction is our
            priority, and we work tirelessly to exceed your expectations in
            every interaction.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30 text-white  rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl text-center mb-2">
            Empower Your Finances Today
          </h3>
          <p class="text-sm">
            Join hands with KS Finance and embark on a jaunty of Financial
            empowerment, where your success is our mission.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30 text-white  rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl text-center mb-2">
            Turn Your Travel Dreams Into Reality
          </h3>
          <p class="text-sm">
            Embark on your dream vacation effortlessly with 13 Finance! Discover
            the best travel Loans in India with flexible repayment tenures and
            competitive interest rates.
          </p>
        </div>
      </div>
    </>
  );
};
