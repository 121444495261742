import React from "react";
import tile1 from "../Images/HomeInterior/seat.jpg";
import tile2 from "../Images/HomeInterior/pooja.jpg";
import tile3 from "../Images/HomeInterior/tv.jpg";
import tile4 from "../Images/HomeInterior/book.jpg";
import Finance from "../Images/Travel/innova-crysta-2.jpg";
import Tours from "../Images/Travel/download.jpeg";

export const InteriorOffer = () => {
  return (
    <>
      <div>
        <div className="bg-[#472B40] bg-fixed">
          <h6 className="text-3xl text-center font-bold text-white p-10">
            We’ve Got Space Saving Designs With Extra Storage
          </h6>
        </div>
        <div
          id="cards_landscape_wrap-2"
          className="text-center bg-white px-10"
        >
          <div className="container pb-20 ">
            <div className="flex flex-wrap justify-center -mx-4">
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile1}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <p className="text-md">
                          Transform your foyer into a versatile space with our
                          Seat and Store solution, combining seating comfort
                          with clever storage functionality.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile2}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <p className="text-md">
                          Experience divine tranquility with our Wall-Mount
                          Pooja Unit, seamlessly blending spirituality and
                          elegant design for your sacred space.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile3}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <p className="text-md">
                          Combine style and functionality seamlessly with our TV
                          unit featuring ample storage solutions, ensuring a
                          clutter-free entertainment space.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4">
                <a href="">
                  <div className="card-flyer transition duration-200 ease-in bg-white rounded-lg shadow-md hover:bg-white hover:shadow-xl">
                    <div className="text-box">
                      <div className="image-box">
                        <img
                          src={tile4}
                          alt=""
                          className="w-full h-48 object-cover"
                        />
                      </div>
                      <div className="text-container p-6">
                        <p className="text-md">
                          Effortlessly combine style and functionality with our
                          bookshelf featuring ample storage, adding both charm
                          and practicality to your space.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
