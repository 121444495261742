import React from "react";

export const CateringList = () => {
  return (
    <>
      <div class="bg-cover bg-no-repeat bg-fixed  bg-caomee  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 gap-4  px-16  py-16">
        <div class="backdrop-saturate-200 bg-white/30  rounded-lg shadow-md px-4 py-8  transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl mb-2 text-center text-white">
            {" "}
            Affordable Price
          </h3>
          <p class="text-sm text-white">
            We take pleasure in pleasing our esteemed customers by offering them
            strong support and various catering services in Chennai at the most
            competitive costs that benefit us both. We are in the business of
            offering a variety of reasonably priced catering services in
            Chennai.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30   rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl mb-2 text-center text-white">
            {" "}
            Fresh and Organic
          </h3>
          <p class="text-sm text-white">
            With the goal to provide our valued clients with hygienic catering
            services in Chennai using fresh organic foods, we make sure that we
            maintain to a precise healthy routine.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30   rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl mb-2 text-center text-white">
            {" "}
            More varieties
          </h3>
          <p class="text-sm text-white">
            A prestigious corporate meet-to-household party bash, catering
            services could either be bone-chain elegant or paper-plate casual
            but it should serve good-quality food no matter how big or small the
            event is, and we are here to vouch for it.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30   rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl mb-2 text-center text-white">
            {" "}
            Industry Experienced Cooks
          </h3>
          <p class="text-sm text-white">
            Our professional cooks have years of experience in this industry and
            are extremely knowledgeable about all the procedures and functions
            you maintain to, so they treat your catering services with the same
            level of care as if they were their own.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30   rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl mb-2 text-center text-white">
            {" "}
            Fast and Efficient Processing
          </h3>
          <p class="text-sm text-white">
            Time is money. Benefit from our streamlined processes, ensuring
            swift and efficient handling of your financial transactions and loan
            applications.
          </p>
        </div>
        <div class="backdrop-saturate-200 bg-white/30   rounded-lg shadow-md px-4 py-8 transform hover:scale-105 transition duration-300">
          <h3 class="font-bold text-xl mb-2 text-center text-white">
            {" "}
            Serving More Events
          </h3>
          <p class="text-sm text-white">
            Our K3 Catering services in Chennai celebrate many occasions such as
            weddings, birthday parties, bachelor parties, and anniversaries with
            exemplary food choices to complete the festivities.
          </p>
        </div>
      </div>

      {/* <div class="bg-cover bg-no-repeat bg-fixed  bg-caom py-12 flex justify-center">
        <div class="catcontainer  bg-opacity-20 max-w-4xl w-full px-8 py-16 rounded-lg ">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div class="list flex flex-col items-center bg-purple-600 bg-opacity-20 rounded-lg p-10 relative">
              <div class="wrapper">
                <h3 class="text-center text-white text-xl font-semibold mb-2">
               
                </h3>
                <p class="text-white text-sm">
                  {" "}
             
                </p>
              </div>
              <i class="fa fa-shopping-cart absolute top-0 right-0 p-4 bg-purple-900 bg-opacity-80 text-white"></i>
            </div>

            <div class="list flex flex-col items-center bg-purple-600 bg-opacity-20 rounded-lg p-10 relative">
              <div class="wrapper">
                <h3 class="text-center text-white text-xl font-semibold mb-2">
               
                </h3>
                <p class="text-white text-sm">
                  {" "}
                
                </p>
              </div>
              <i class="fa fa-shopping-cart absolute top-0 right-0 p-4 bg-purple-900 bg-opacity-80 text-white"></i>
            </div>

            <div class="list flex flex-col items-center bg-purple-600 bg-opacity-20 rounded-lg p-10 relative">
              <div class="wrapper">
                <h3 class="text-center text-white text-xl font-semibold mb-2">
             
                </h3>
                <p class="text-white text-sm">
                  {" "}
                 
                </p>
              </div>
              <i class="fa fa-shopping-cart absolute top-0 right-0 p-4 bg-purple-900 bg-opacity-80 text-white"></i>
            </div> */}

      {/* <div class="list flex flex-col items-center bg-purple-600 bg-opacity-20 rounded-lg p-10 relative">
              <div class="wrapper">
                <h3 class="text-center text-white text-lg font-semibold mb-2">
                  Industry Experienced Cooks
                </h3>
                <p class="text-white text-sm">
                  {" "}
                  Our professional cooks have years of experience in this
                  industry and are extremely knowledgeable about all the
                  procedures and functions you maintain to, so they treat your
                  catering services with the same level of care as if they were
                  their own.
                </p>
              </div>
              <i class="fa fa-shopping-cart absolute top-0 right-0 p-4 bg-purple-900 bg-opacity-80 text-white"></i>
            </div> */}
      {/* 
            <div class="list flex flex-col items-center bg-purple-600 bg-opacity-20 rounded-lg p-10 relative">
              <div class="wrapper">
                <h3 class="text-center text-white text-xl font-semibold mb-2">
                 
                </h3>
                <p class="text-white text-sm">
                  {" "}
              
                </p>
              </div>
              <i class="fa fa-shopping-cart absolute top-0 right-0 p-4 bg-purple-900 bg-opacity-80 text-white"></i>
            </div>

            <div class="list flex flex-col items-center bg-purple-600 bg-opacity-20 rounded-lg p-10 relative">
              <div class="wrapper">
                <h3 class="text-center text-white text-xl font-semibold mb-2">
               
                </h3>
                <p class="text-white text-sm">
                  {" "}
                
                </p>
              </div>
              <i class="fa fa-shopping-cart absolute top-0 right-0 p-4 bg-purple-900 bg-opacity-80 text-white"></i>
            </div> */}

      {/* <div class="list flex flex-col items-center bg-purple-600 bg-opacity-20 rounded-lg p-10 relative">
              <div class="wrapper">
                <h3 class="text-center text-white text-lg font-semibold mb-2">
                  Special Dishes
                </h3>
                <p class="text-white text-sm">
                  {" "}
                  We highlight your event's uniqueness to make it the focus of
                  the town. We offer plenty of cuisines to provide you with the
                  best wedding catering services in Chennai that you deserve.
                </p>
              </div>
              <i class="fa fa-shopping-cart absolute top-0 right-0 p-4 bg-purple-900 bg-opacity-80 text-white"></i>
            </div> */}

      {/* <div class="list flex flex-col items-center bg-purple-600 bg-opacity-20 rounded-lg p-10 relative">
              <div class="wrapper">
                <h3 class="text-center text-white text-xl font-semibold mb-2">
                
                </h3>
                <p class="text-white text-sm">
                  {" "}
              
                </p>
              </div>
              <i class="fa fa-shopping-cart absolute top-0 right-0 p-4 bg-purple-900 bg-opacity-80 text-white"></i>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="items-center bg-cover bg-cateringlist">
        <div className="catcontainer">
          <div class="list">
            <div class="wrapper">
              <h3>Affordable Price</h3>
              <p>
                We take pleasure in pleasing our esteemed customers by offering
                them strong support and various catering services in Chennai at
                the most competitive costs that benefit us both. We are in the
                business of offering a variety of reasonably priced catering
                services in Chennai.
              </p>
            </div>
            <i class="fa fa-shopping-cart"></i>
          </div>
          <div class="list">
            <div class="wrapper">
              <h3>Fresh and Organic</h3>
              <p>
                With the goal to provide our valued clients with hygienic
                catering services in Chennai using fresh organic foods, we make
                sure that we maintain to a precise healthy routine.
              </p>
            </div>
            <i class="fa fa-shopping-cart"></i>
          </div>
          <div class="list">
            <div class="wrapper">
              <h3>More varieties</h3>
              <p>
                A prestigious corporate meet-to-household party bash, catering
                services could either be bone-chain elegant or paper-plate
                casual but it should serve good-quality food no matter how big
                or small the event is, and we are here to vouch for it.
              </p>
            </div>
            <i class="fa fa-shopping-cart"></i>
          </div>
          <div class="list">
            <div class="wrapper">
              <h3>Industry Experienced Cooks</h3>
              <p>
                Our professional cooks have years of experience in this industry
                and are extremely knowledgeable about all the procedures and
                functions you maintain to, so they treat your catering services
                with the same level of care as if they were their own.
              </p>
            </div>
            <i class="fa fa-shopping-cart"></i>
          </div>
          <div class="list">
            <div class="wrapper">
              <h3>Special Dishes</h3>
              <p>
                We highlight your event's uniqueness to make it the focus of the
                town. We offer plenty of cuisines to provide you with the best
                wedding catering services in Chennai that you deserve.
              </p>
            </div>
            <i class="fa fa-shopping-cart"></i>
          </div>
          <div class="list">
            <div class="wrapper">
              <h3>Serving More Events</h3>
              <p>
                Our K3 Catering services in Chennai celebrate many occasions
                such as weddings, birthday parties, bachelor parties, and
                anniversaries with exemplary food choices to complete the
                festivities.
              </p>
            </div>
            <i class="fa fa-shopping-cart"></i>
          </div>
        </div>
      </div> */}
    </>
  );
};
