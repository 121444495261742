import React from "react";
import insta from "../Images/icons/skill-icons_instagram.png";
import linked from "../Images/icons/linked.png";
import twitter from "../Images/icons/twi.png";
import facebook from "../Images/icons/logos_facebook.png";
import youtube from "../Images/icons/logos_youtube-icon.png";
import logo from "../Images/logo/k3logo.png";

export const FooterTab = () => {
  return (
    <>
      <footer
        id="footer"
        className="bg-bycolor bg-[#472B40]  p-8 flex flex-wrap justify-evenly  "
      >
        <div className="mb-4 ">
          <h2 className=" text-base xl:text-3xl  lg:text-2xl md:text-xl sm:text-base font-semibold mb-4 text-[#E4F1FF]">
            About Us
          </h2>
          <p className="text-white  text-xs xl:text-base  lg:text-base md:text-sm sm:text-xs">
            Innovation, Expertise, and Personalized Service Redefined.
          </p>
          <img
            className="xl:mt-5 lg:mt-5 md:mt-4 sm:mt-3 mt-3 xl:h-20 lg:h-12 md:h-9 sm:h-10 h-9 rounded-md"
            src={logo}
            alt=""
          />
        </div>
        <div>
          <h2 className="text-base xl:text-3xl  lg:text-2xl md:text-xl sm:text-base  font-semibold mb-4 text-[#E4F1FF]">
            Contact Us
          </h2>
          <p className="text-[#E4F1FF]  text-xs xl:text-base  lg:text-base md:text-sm sm:text-xs">
            No : 26/2B, Plot No : 5B,
            <br />
            Senthil Nagar, 8th street, Seevaram,
            <br />
            Okkiyam Thuraipakkam,
            <br />
            Chennai 600096.
            <br />
            <br />
            Phone: 9940383228
            <br />
            <br />
            Email: k3company.serv@gmail.com
            <br />
            <br />
            GSTIN: 33BUGPK8875E1ZD
          </p>
          <div className="flex items-center xl:mt-5 lg:mt-5 md:mt-5 sm:mt-3 mt-3 ">
            <a href="" target="_blank">
              <img className="mediaicon" src={insta} alt="" />
            </a>

            <a href="" target="_blank">
              {" "}
              <img
                className="xl:ml-2 lg:ml-2 md:ml-2 sm:ml-2 ml-2 mediaicon"
                src={facebook}
                alt=""
              />
            </a>
            <a href="" target="_blank">
              {" "}
              <img
                className="xl:ml-2 lg:ml-2 md:ml-2 sm:ml-2 ml-2 mediaicon"
                src={youtube}
                alt=""
              />
            </a>
          </div>
        </div>
      </footer>

      <footer
        id="footer"
        className="bg-bycolor bg-[#472B40] py-5 flex flex-wrap  justify-evenly  text-xs xl:text-base  lg:text-base md:text-sm sm:text-xs"
      >
        <div className="mb-4 ">
          <p className="text-[#E4F1FF]">
            Terms and Conditions / Privacy Policy / Sitemap
          </p>
        </div>
        <div>
          <p className="text-[#E4F1FF]">
            Copyright 2024 © K3Company All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  );
};
