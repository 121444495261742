import React from "react";

import chennai from "../Images/HomeInterior/table-8403065.jpg";

export const HomeInterior = () => {
  return (
    <>
      <div className="bg-interiorback bg-cover  h-screen  flex justify-center items-center">
        <div
          className="designcard  text-center p-12 m-5"
          data-aos="flip-right"
          data-aos-duration="700"
        >
          <h6 className="primary-text ">K3 Interior Services</h6>
          <p className="secondary-text">Dream Homes At Dream Budgets</p>
        </div>
      </div>

      <div className="bg-white py-20 px-20">
        <h1
          className=" font-extrabold text-center  text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl "
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <span className="k3serv text-2xl lg:text-5xl xl:text-5xl md:text-3xl sm:text-3xl">
            {" "}
            K3 Home Interior Design
          </span>
        </h1>
        <p
          className="font-normal text-justify  lg:text-base lg:mt-5 md:mt-3 sm:mt-2   xl:text-base sm:text-xs md:text-base text-base  "
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          At K3 Home Interior Designs, we excel in creating timeless spaces with
          a blend of innovation and style. Our expert team of designers is
          dedicated to transforming your vision into a personalized masterpiece.
          From wardrobes to beds and innovative storage solutions, explore the
          endless possibilities with K3, where design is an art, and your home
          is our canvas.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-4 bg-white py-20 px-10 md:px-20 sm:px-20 lg:px-36 xl:px-36">
        <div className="flex items-center justify-center">
          <div>
            <p className="mt-4 text-justify text-base">
              Embark on the journey of crafting your dream home with DesignCafe,
              where choices abound and innovations thrive. Explore the dilemma
              of sliding or swing door wardrobes, the versatility of Murphy beds
              or beds with storage, and the organizational wonders of magic
              corner units, D-carousels, lift-up shutters, wall units, and
              janitor units. Our repertoire extends to TV cum study units, pooja
              units, and beyond, offering a comprehensive array of interior
              design solutions.
              <br />
              <br />
              Navigating the vast world of interior design, DesignCafe stands as
              a beacon, illuminating design styles, color combinations, layouts,
              space-saving innovations, accessories, and decor. Wondering about
              the perfect balance for your home? Look no further, for we boast a
              team of 400+ designers ready to guide you through the intricacies
              of creating a space that reflects your vision.
              <br />
              <br />
              At DesignCafe, we believe in transforming spaces into a reflection
              of your lifestyle, making every inch count. With our expertise,
              you can turn your home into a sanctuary that not only speaks to
              your aesthetic preferences but also maximizes functionality.
              Explore the endless possibilities with DesignCafe, where home
              interior design is an art, and your dream home is our canvas.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <img src={chennai} alt="Deer" className="w-full h-full rounded-xl" />
        </div>
      </div>

      {/* <div className="flex flex-wrap justify-center  py-36">
        <div className="w-1/5 p-4">
          <img
            src={realEstate}
            alt="Image 1"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Premier Real Estate</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={catering}
            alt="Image 2"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Taste the Moment</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={Finance}
            alt="Image 3"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">MicroMint Finance</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={Tours}
            alt="Image 4"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Epic Journeys Await</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={Design}
            alt="Image 5"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Dream Home Design</p>
        </div>
      </div>
      <h1 className="text-white text-2xl">Interior Design</h1> */}
    </>
  );
};
