import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavbarSka from "./Components/NavTop";
import { HeroToper } from "./Components/HeroTop";
import { MangSecurity } from "./Components/MangSec";
import { SecurityCenter } from "./Components/Security";
import { FooterTab } from "./Components/Footer";
import { Environments } from "./Components/k3tech";
import { AbouTops } from "./About/AbouTop";
import ScrollToTopButton from "./Components/ScrollToTopButton";

import { Travels } from "./Travels/ToursAndTravels";
import { TermsAndCondition } from "./Travels/TermsAndCondition";
import { RealEstate } from "./RealEstate/RealEstate";
import { Catering } from "./Catering/Catering";
import { HomeInterior } from "./HomeInterior/InteriorDesign";
import { SmallFinance } from "./SmallFinance/Finance";
import { Travcards } from "./Travels/Travcard";
import { Traveshad } from "./Travels/Travesha";
import { Travtwo } from "./Travels/Travtw";
import { Travteo } from "./Travels/Travte";
import { Travswing } from "./Travels/Travswin";
import { CateringHead } from "./Catering/CateringHead";
import { CateringTab } from "./Catering/CateringTab";
import { CateringList } from "./Catering/CateringList";
import { Realnext } from "./RealEstate/Realne";

// ---
import { InteriorMulti } from "./HomeInterior/InteriorMulti";
import { InteriorOffer } from "./HomeInterior/InteriorOfferCard";
import { InteriorBed } from "./HomeInterior/InteriorBedroom";
import { InteriorLiving } from "./HomeInterior/InteriorLiving";
import { InteriorList } from "./HomeInterior/InteriorList";

// --------
import { FinanceList } from "./SmallFinance/FinanceList";
import { FinanceCard } from "./SmallFinance/FinanceCard";
import { Realcard } from "./RealEstate/Realcar";
import { Realproper } from "./RealEstate/Realpro";
import { Realbutt } from "./RealEstate/Realbu";

// export function signIn() {
//   signInWithPopup(auth, provider)
//     .then((data) => {
//       localStorage.setItem("userEmail", data?.user?.email);
//       window.location.reload();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }
function App() {
  return (
    <>
      <NavbarSka />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroToper />
                <MangSecurity />
                <SecurityCenter />
                <Environments />
              </>
            }
          />
          <Route
            path="services/travels"
            element={
              <>
                <Travels />
                {/* <Travcards/> */}
                <Traveshad />
                <Travtwo />
                <Travteo />
                <Travswing />
                <TermsAndCondition />
              </>
            }
          />
          <Route
            path="services/finance"
            element={
              <>
                <SmallFinance />
                <FinanceList />
                <FinanceCard />
              </>
            }
          />
          <Route
            path="services/catering"
            element={
              <>
                <Catering />
                <CateringHead />
                <CateringList />
                <CateringTab />
              </>
            }
          />
          <Route
            path="services/realestate"
            element={
              <>
                <RealEstate />
                <Realnext />
                <Realcard />
                <Realbutt />
                <Realproper />
              </>
            }
          />

          <Route
            path="services/interiordesign"
            element={
              <>
                <HomeInterior />
                <InteriorMulti />
                <InteriorOffer />
                <InteriorBed />
                <InteriorLiving />
                <InteriorList />
              </>
            }
          />
          <Route
            path="aboutus"
            element={
              <>
                <AbouTops />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
      <FooterTab />
      <ScrollToTopButton />
    </>
  );
}

export default App;
