import React from "react";
import realEstate from "../Images/Travel/IMG_20151207_165956.jpg";
import catering from "../Images/Travel/right-front-three-quarter0.webp";
import Finance from "../Images/Travel/innova-crysta-2.jpg";
import Tours from "../Images/Travel/download.jpeg";
// import Design from "../Images/3d-rendering-loft-luxury-living-room-with-shelf-near-dining-table_105762-2052.avif";

export const Travcards = () => {
  return (
    <>
      <div className="flex flex-wrap justify-center  bg-white py-36 shadow-lg hover:shadow-xl">
        <div className="w-1/5 p-4">
          <img
            src={realEstate}
            alt="Image 1"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Swift Dzire</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={catering}
            alt="Image 2"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Toyota Etios</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={Finance}
            alt="Image 3"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Innova</p>
        </div>
        <div className="w-1/5 p-4">
          <img
            src={Tours}
            alt="Image 4"
            className="w-full h-auto rounded-md transform hover:scale-105 transition duration-300"
          />
          <p className="py-5 text-center">Epic Journeys Await</p>
        </div>
      </div>
    </>
  );
};
